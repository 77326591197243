<template>
  <div>
    <div class="title">近7天星钻记录</div>
    <el-divider></el-divider>
    <div></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.title {
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
}
.el-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}
.simply {
  background: #f6f7f8;
  border-radius: 6px;
  padding: 15px;

  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
  color: #333333;
}
</style>
